<template>
    <div class="nav-bar-maintenance">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    
    <div class="void-new-unit"></div>

    <div class="form-section">
        <h4>Perfil de usuario</h4>
        <img src="@/assets/client-icon.png">
    </div>

    <div class="container">
        <div class="profile-header">
            <img src="@/assets/client-icon.png">
            <h2>{{ localClientDetails.client_name }}</h2>
            <button type="submit" class="btn-submit-upload">Subir imagen</button>
        </div>

        <div class="section">
            <div class="form-row">
                <label>ID:</label>
                <input type="text" placeholder="Introduce el ID" v-model="localClientDetails.client_ident"/>
            </div>
            <div class="form-row">
                <label>Nombre(s):</label>
                <input type="text" placeholder="Introduce el nombre" v-model="localClientDetails.client_name"/>
            </div>
            <div class="form-row">
                <label>Teléfono</label>
                <input type="text" placeholder="Introduce el primer telefono" v-model="localClientDetails.phone"/>
            </div>
            <div class="form-row">
                <label>Estatus</label>
                <select v-model="localClientDetails.client_status">
                    <option value=1>Activo</option>
                    <option value=2>Inactivo</option>
                </select>
            </div>
            <div class="form-row">
                <label>Cliente recurrente</label>
                <input type="checkbox" v-model="localClientDetails.is_privileged" /><!--Añadir v-model=""-->
            </div>
            <!--
            <div class="form-row">
                <label>Apellido materno:</label>
                <input type="text" placeholder="Introduce el segundo apellido" v-model="localClientDetails.employee_last_name2"/>
            </div>
            <div class="form-row">
                <label>Posición:</label>
                <select v-model="localClientDetails.employee_position">
                    <option value=1>Operador Grúa C-D</option>
                    <option value=2>Gerente Administrativo</option>
                    <option value=3>Tesoreria</option>
                    <option value=4>Rabon OP</option>
                    <option value=5>Administrativo</option>
                    <option value=6>Chofer Administrativo</option>
                    <option value=7>Sistemas</option>
                    <option value=8>Gerente General</option>
                    <option value=9>Jefe Recursos Humanos</option>
                    <option value=10>Credito y Cobranza</option>
                    <option value=11>Operador Grúa A-B</option>
                    <option value=12>Jefe Call Center</option>
                    <option value=13>Vulcanizador</option>
                    <option value=14>Operador de Montacargas</option>
                    <option value=15>Mécanico</option>
                    <option value=16>Ejecutivo Cuenta</option>
                </select>
            </div>
            <div class="form-row">
                <label>Fecha de contratación:</label>
                <input type="text" placeholder="Introduce la fecha de contratación" v-model="localClientDetails.employee_hiring_date"/>
            </div>
            <div class="form-row">
                <label>Departamento:</label>
                <input type="text" placeholder="Introduce el departamento al que pertenece el personal" v-model="localClientDetails.employee_department"/>
            </div>
            -->
        </div>

        <div class="section address" v-if="localClientDetails.client_address">
            <h3>Dirección</h3>
            <div class="form-row">
                <label>Calle y número</label>
                <input type="text" placeholder="Introduce la calle" v-model="localClientDetails.client_address.street"/>
            </div>
            <div class="form-row">
                <label>Colonia</label>
                <input type="text" placeholder="Introduce la colonia" v-model="localClientDetails.client_address.neighborhood"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-row">
                <label>Municipio</label>
                <input type="text" placeholder="Introduce el municipio" v-model="localClientDetails.client_address.city"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-row">
                <label>Código postal</label>
                <input type="text" placeholder="Introduce el código postal" v-model="localClientDetails.client_address.codigoPostal"/> <!--Añadir v-model=""-->
            </div>
        </div>

        <div class="section contacts">
            <h3>Datos fiscales</h3>
            <div class="form-row">
                <label>RFC</label>
                <input type="text" placeholder="Introduce el RFC" v-model="localClientDetails.client_rfc"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-row">
                <label>Régimen Fiscal</label>
                <input type="text" placeholder="Introduce el régimen fiscal" v-model="localClientDetails.tax_regime"/> <!--Añadir v-model=""-->
            </div>
        </div>

        <div class="footer-buttons">
            <button type="submit" class="btn-submit-save" @click="saveClientProfile">Guardar cambios</button>
    <button type="button" class="btn-submit-cancel" onclick="history.back()">Cancelar</button>
        </div>
    </div>
</template>
  
<style scoped>
.nav-bar-maintenance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background-color: #FFC434;
    z-index: 1000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}

.container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-header img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
}

.profile-header h2 {
    margin: 0;
    font-size: 24px;
}

.profile-header button {
    margin-left: auto;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profile-header button:hover {
    background-color: #0056b3;
}

.section {
    margin-bottom: 30px;
}

.section h3 {
    font-size: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.form-row label {
    flex: 1 1 25%;
    font-weight: bold;
    margin-right: 10px;
}

.form-row input, .form-row select {
    flex: 1 1 70%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.address, .contacts {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 20px;
}

.address p, .contacts p {
    margin: 5px 0;
}

.footer-buttons {
    display: flex;
    justify-content: flex-end;
}

.footer-buttons button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.footer-buttons .save {
    background-color: #28a745;
    color: #fff;
}

.footer-buttons .save:hover {
    background-color: #218838;
}

.footer-buttons .cancel {
    background-color: #dc3545;
    color: #fff;
}

.footer-buttons .cancel:hover {
    background-color: #c82333;
}
</style>
  
<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    props: ['clientDetails'],
    data() {
        return {
            localClientDetails: {...this.clientDetails},
            client_ident: this.clientDetails.client_ident,
            client_name: this.clientDetails.client_name,
            phone: this.clientDetails.phone,
            client_address: this.clientDetails.client_address,
            client_rfc: this.clientDetails.client_rfc,
            client_status: this.clientDetails.client_status,
            is_privileged: this.clientDetails.is_privileged,
            tax_regime: this.clientDetails.tax_regime,
            saving_client: false,
        }
    },
    watch: {
        clientDetails: {
            deep: true,
            handler(newVal) {
                this.localClientDetails = { ...newVal };
            }
        }
    },
    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        saveClientProfile: async function() {
            try {
                const client_id = this.clientDetails.id; // Ensure this matches the actual ID property
                const apiDirectory = process.env.API_DIRECTORY;
                this.saving_client = true
                await axios.put(`${apiDirectory}/client/${client_id}/`, this.localClientDetails);
                Swal.fire(
                    '¡Actualizado!',
                    'Los datos del formulario se han actualizado con éxito.',
                    'success'
                )
                this.saving_client = false;
                // Additional actions after success, e.g., redirecting or updating local state
            } catch (error) {
                console.error(error)
                this.saving_client=false
                Swal.fire('Error', 'Hubo un problema al actualizar los datos del cliente.', 'error');
                
            }
        },
    },
    async created () {
        this.localClientDetails = { ...this.clientDetails };
    }
}
</script>