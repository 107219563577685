<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button" />
    </div>
    <div class="text-operations"></div>
    <div class="form-section">
        <h3>Creación de factura agrupada</h3>
        <img src="@/assets/filtros.png">
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label for="note_ident">Identificador de la nota</label>
                <input type="text" id="note_ident" v-model="noteIdent" placeholder="Ingrese el identificador de la nota a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label>Seleccione la fecha de inicio</label>
                <input type="datetime-local" v-model="startDate" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                <label>Seleccione la fecha del final</label>
                <input type="datetime-local" v-model="endDate" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="client_name">Cliente</label>
                <input type="text" id="client_name" v-model="clientName" placeholder="Ingrese el cliente a buscar" />
            </div>
        </div>
        <!-- Lo del cliente se va a reemplazar por este código
            <div class="form-group-filters">
                    <label for="client_name">Cliente</label>
                    <input type="text" id="client_name" placeholder="Escribe y seleccione el nombre del cliente" v-model="client_name" @input="filterClients" autocomplete="off" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    <div class="client-dropdown" v-if="filteredClients.length" v-click-outside="closeDropdown">
                        <div v-for="client in filteredClients" :key="client.id" @click="selectClient(client)">
                            {{ client.client_name }}
                        </div>
                    </div>
                </div>
        -->
        <div class="form-section">
            <div class="form-group-filters">
                <label for="origin">Origen</label>
                <input type="text" id="origin" v-model="origin" placeholder="Ingrese el origen a buscar" />
            </div>
        </div>
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label for="destination">Destino</label>
                <input type="text" id="destination" v-model="destination" placeholder="Ingrese el destino a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="unit">Unidad</label>
                <input type="text" id="unit" v-model="unit" placeholder="Ingrese la unidad a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="operator">Operador</label>
                <input type="text" id="operator" v-model="operator" placeholder="Ingrese el operador a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="file">Expediente</label>
                <input type="text" id="file" v-model="file" placeholder="Ingrese el expediente a buscar" />
            </div>
        </div>
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label for="clerk">Capturista</label>
                <input type="text" id="clerk" v-model="clerk" placeholder="Ingrese el capturista a buscar" />
            </div>
        </div>
        <!--
        <div class="form-section">
            <div class="form-group-filters">
                <label for="??">Seleccione los tipos de nota a buscar</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Asistencia Vial</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Auxilio Vial</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Montacarga</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Lote</label>
            </div>
        </div>
    -->
        <div class="form-section">
            <div class="form-group-filters">
                <label for="??">Seleccione el estatus de la nota</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isActive" v-model="isActive" />
                <span style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isAssigned" v-model="isAssigned"/>
                <span style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isProgrammed" v-model="isProgrammed"/>
                <span style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isCanceled" v-model="isCanceled"/>
                <span style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isCompleted" v-model="isCompleted"/>
                <span style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
            </div>
        </div>

        <!--
        <div class="form-section">
            <div class="form-group-filters">
                <label for="??">Seleccione que tenga carta porte y/o factura</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Facturado</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Facturado con carta porte</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>No facturado</label>
            </div>
        </div>
    -->
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label for="plate">Placa</label>
                <input type="text" id="plate" v-model="plate" placeholder="Ingrese la placa a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="engine">Serie del motor</label>
                <input type="text" id="engine" v-model="engine" placeholder="Ingrese la serie del motor a buscar" />
            </div>
        </div>
    </div>
    <button type="submit" class="btn-submit-save" @click="queryOrders">Aplicar filtros</button>

    <!-- Table for scheduled orders -->
    <div class="table-container">
        <h2>Resultados</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>Seleccionar</th>
                    <th>ID</th>
                    <th>Fecha y hora de solicitud</th>
                    <th>Cliente</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Fecha y hora de inicio</th>
                    <th>Fecha y hora de contacto</th>
                    <th>Unidad</th>
                    <th>Operador</th>
                    <th>Expediente</th>
                    <th>Capturista</th>
                    <th>Tipo</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in queriedOrders" :key="index">
                    <td>
                        <span
                            style="background-color: orangered; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{      }}</span>
                    <input type="checkbox" :value="order.note_ident" v-model="selectedNotes" />
                </td>
                    <td @click="viewNoteDetails(order.type, order.note_ident)">
                        <span
                            style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{
                            order.note_ident }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.destination.neighborhood }}, {{ order.destination.city }}, {{ order.destination.state
                        }}</td> <!-- Destination -->
                    <td><b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                            order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.start_time.substring(11, 16) }}</td>
                    <!-- Start time -->
                    <td><b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                            order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.contact_time.substring(11, 16) }}</td>
                    <!-- Contact time -->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident}} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                    <th></th>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPage" :disabled="currentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in TotalPages" :key="pageNumber"
                @click="goToPage(pageNumber)"
                :class="{ active: pageNumber === currentPage }">{{ pageNumber }}</span>
            <button @click="nextPage" :disabled="currentPage >= TotalPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
        <button type="submit" class="btn-submit-save" @click="handleSelectedNotes">Facturar</button>
    </div>
    
</template>

<style>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}

.logo-button {
    position: absolute; 
    height: 90%; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}

.text-operations {
    margin-top: 10vh; /* Adjust this value to ensure it's below the nav bar */
    left: 0;
    width: 100%;
    height: 5vh;
    border-bottom: 1px solid black;
    background-color: #ffffff;
    z-index: 999; /* Ensure it's above other content */
    font-size: 30px;
    display: flex;
    justify-content: space-between; /* Adjust spacing between elements */
    align-items: center; /* Align items vertically */
    overflow: hidden; /* Prevent text overflow */
}

.text-operations h2 {
    font-size: inherit; /* Adjust font size as needed */
    margin: 0;
    padding: 0;
    margin-left: 2vh;
}

.form-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}

.form-section{
    flex-basis: calc(33% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 10px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
}

.form-section-images img{
    width: 100px;
    height: 100px;
}

.image-container {
    margin-top: 10vh;
    width: 100%;
    height: 200px;
}

.image-container-upload {
    margin-top: 2vh;
    margin-bottom: 3vh;
    width: 100%;
    height: 200px;
}

.form-group-filters-images{
    height: 8vh;
}

.image-container-upload img{
    width: 200px !important;
    height: 200px !important;
}

.form-section:last-child {
    margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
}

h2 {
    margin-bottom: 20px; /* Espacio debajo de los títulos de sección */
}

.form-group-filters label {
    text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
    font-weight: bold; /* Hace que el texto de la etiqueta sea negrita */
    display: inline-block; /* Hace que la etiqueta sea en bloque pero alinee su contenido */
    width: 400%; /* Asegura que la etiqueta ocupe todo el ancho disponible */
}

.form-section img{
    width: 40px;
    height: 40px;
}

.form-group-filters input{
    width: 100%;
}

.checkbox-group {
    display: flex;
    justify-content: flex-start; 
    gap: 5px; 
    font-weight: bold;
}

.checkbox-group input{
    margin-bottom: 1vh;
}

.table-container {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 0;
    padding: 1vh;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.6em; 
}

.table th, .table td {
    border-left: none;
    border-right: none;
    padding: 5px; 
    text-align: left;
}

.table tbody tr:first-child td {
    border-top: none;
}

.table tbody tr:hover td {
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}

.table tbody tr:last-child td {
    border-bottom: none;
}

.table thead input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border-width: 1px;
}

.table th{
    border-left: none;
    border-right: none;
    padding: 10px;
    text-align: left
}

.table td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.pagination-container {
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.pagination-container button {
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    margin: 0 10px;
    background-color: transparent; 
    border: none; 
}

.pagination-container button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.pagination-container span {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-container span.active {
    background-color: #FFC434;
}

.pagination-container button img {
    width: 25px; 
    height: auto; 
}
</style>

<script>

import axios from 'axios';
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            noteIdent: '',
            startDate: '',
            endDate: '',
            clientName: '',
            origin: '',
            destination: '',
            unit: '',
            operator: '',
            file: '',
            clerk: '',
            plate: '',
            engine: '',
            queriedOrders: [],
            selectedNotes: [],
            currentPage: 1,
            pageSize:20,
            nOrders: 1,
            StatusCounts: {
                Activo: 1, // Example hardcoded value
                Cancelado: 1, // Example hardcoded value
                Programado: 1, // Example hardcoded value
                Completado: 1, // Example hardcoded value
                Asignada: 1
            },
            isActive: '',
            isAssigned: '',
            isCanceled: '',
            isCompleted: '',
            isProgrammed: ''

        };
    },

    computed: {

        PaginatedOrders() {
            //**Contains page of orders with status NE to "Programado" */
            return this.queriedOrders
        },

        TotalPages() {
            return Math.ceil((this.StatusCounts.Activo + this.StatusCounts.Asignada + this.StatusCounts.Programado, + this.StatusCounts.Completado + this.StatusCounts.Asignada) / this.pageSize);
            // return Math.ceil(this.assistance_n_inProcessOrders / this.assistancePageSize);
        },
    },

    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },

        viewNoteDetails(notetype, noteID) {
            // Calls EditNote component for aux or assistance notes
            Swal.fire({
                title: '¿Estás seguro?',
                text: `¿Quieres ver los detalles de la nota ${noteID}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, ver detalles',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'EditNote', params: { notetype, noteID } });
                }
            });
        },

        goToPage(pageNumber) {
            this.currentPage = pageNumber;
            const page = this.currentPage
            this.queryOrders(page);
        },

        nextPage() {
            if (this.currentPage < this.TotalPages) this.currentPage++;
            const page = this.currentPage
            this.queryOrders(page);
        },

        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
            const page = this.currentPage
            this.queryOrders(page);
        },

        handleSelectedNotes() {
            console.log(this.selectedNotes); // Array of selected note IDs
        },

        async fetchStatistics(){
            const payload = {
                note_ident: this.noteIdent,
                start_date: this.startDate,
                end_date: this.endDate,
                client_name: this.clientName,
                origin: this.origin,
                destination: this.destination,
                unit: this.unit,
                operator: this.operator,
                file: this.file,
                clerk: this.clerk,
                plate: this.plate,
                engine: this.engine,
                is_active: this.isActive,
                is_completed: this.isCompleted,
                is_assigned: this.isAssigned,
                is_canceled: this.isCanceled,
                is_programmed: this.isProgrammed
            };
            let response 
            const apiDirectory = process.env.API_DIRECTORY;
            // Request schedules status as or condition (Check view for details)
            try{
            response = await axios.post(`${apiDirectory}/get-global-filtered-statistics`, payload);
                const statusDict = {
                    1: "Activo",
                    2: "Cancelado",
                    3: "Programado",
                    4: "Completado",
                    5: "Asignada"
                };
                // Initialization to avoid undefined on bar
                let statusCounts = {
                    "Activo":0,
                    "Cancelado":0,
                    "Programado":0,
                    "Completado":0,
                    "Asignada":0
                };
                // Assign based on response 
                // console.log(response.data);
                const responseData = response.data;
                // console.log(responseData);
                responseData.forEach(item => {
                    const status = statusDict[item.status]; // Get the name from the statusDict
                    const count = item.count;
                    statusCounts[status] = count;
                });
                // Returned from statistics endpoint 
                this.StatusCounts = statusCounts;
            }
            catch(error){
                console.error("There was an error fetching the statistics",error)
            }
        },

        async queryOrders(){
            this.fetchStatistics()
            const payload = {
                note_ident: this.noteIdent,
                start_date: this.startDate,
                end_date: this.endDate,
                client_name: this.clientName,
                origin: this.origin,
                destination: this.destination,
                unit: this.unit,
                operator: this.operator,
                file: this.file,
                clerk: this.clerk,
                plate: this.plate,
                engine: this.engine,
                is_active: this.isActive,
                is_completed: this.isCompleted,
                is_assigned: this.isAssigned,
                is_canceled: this.isCanceled,
                is_programmed: this.isProgrammed
            };

            try {
                const apiDirectory = process.env.API_DIRECTORY;
                // Set responseType to 'blob' to handle file download
                const response = await axios.post(`${apiDirectory}/get-global-filtered-orders?page=${this.currentPage}&page_size=${this.pageSize}`, payload, {
                });
                
                const responseData = response

                responseData.data.results.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.queriedOrders = responseData.data.results
            } catch (error) {
                const errorMessage = error.response ? error.response.data.error : error.message;
                Swal.fire({
                    icon: 'error',
                    title: 'Error Generando Reporte',
                    text: errorMessage,
                });
            }
        }
    }
}
</script>