<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="create-order">
        <h2 class="order-type">Nota de trabajo: {{ localNoteDetails.note_ident }}</h2>
        <button @click="showForm('initialData')">Datos iniciales</button>
        <button @click="showForm('assignment')">Asignación</button>
        <button @click="showForm('payment')">Cobro</button>
        <button @click="showForm('billing')">Facturación</button>
        <button @click="showForm('images')">Imágenes</button>
    </div>
    <div v-if="currentForm === 'initialData'">
        <div class="form-container">
            <!-- Datos del cliente -->
            <div class="form-section">
                <h3>Datos del cliente</h3>
                <img src="@/assets/client-icon.png">
                <!-- Formulario de datos del cliente -->
                <!-- ...campos de cliente... -->
                <div class="form-group">
                    <label for="client_name">Cliente</label>
                    <input type="text" id="client_name" placeholder="Escribe y seleccione el nombre del cliente" v-model="client_name" @input="filterClients" autocomplete="off" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    <div class="client-dropdown" v-if="filteredClients.length" v-click-outside="closeDropdown">
                        <div v-for="client in filteredClients" :key="client.id" @click="selectClient(client)">
                            {{ client.client_name }}
                        </div>
                    </div>
                </div>
                <div class="button-add-new-client" v-if="!isClientSelected">
                    <button @click="toggleNewClientForm" class="btn-submit-save" v-if="!addNewClient" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Agregar nuevo cliente</button>
                </div>
                <div v-if="isClientSelected">
                    <div class="form-group">
                        <label for="client_street">Calle</label>
                        <label>{{ clientDetails.client_address.street }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_codigoPostal">C.P. (Código Postal)</label>
                        <label>{{ clientDetails.client_address.codigoPostal }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_neighborhood">Colonia</label>
                        <label>{{ clientDetails.client_address.neighborhood }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_city">Ciudad</label>
                        <label>{{ clientDetails.client_address.city }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_state">Estado</label>
                        <label>{{ clientDetails.client_address.state }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_rfc">RFC</label>
                        <label>{{ clientDetails.client_rfc }}</label>
                    </div>
                    <div class="form-group">
                        <label for="phone">Teléfono</label>
                        <label>{{ clientDetails.phone }}</label>
                    </div>
                </div>
                <div v-if="addNewClient">
                    <div class="form-group">
                        <label for="client_street">Calle</label>
                        <input type="text" id="client_street" placeholder="Ingrese una ciudad" v-model="clientDetails.client_address.street" />
                    </div>
                    <div class="form-group">
                        <label for="client_codigoPostal">C.P. (Código Postal)</label>
                        <input type="text" id="client_codigoPostal" placeholder="Ej. 64849" v-model="clientDetails.client_codigoPostal" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group">
                        <label for="client_neighborhood">Colonia</label>
                        <input type="text" id="client_neighborhood" placeholder="Ej. Tecnológico" v-model="client_neighborhood" />
                    </div>
                    <div class="form-group">
                        <label for="client_city">Ciudad</label>
                        <input type="text" id="client_city" placeholder="Ingrese una ciudad" v-model="client_city" />
                    </div>
                    <div class="form-group">
                        <label for="client_state">Estado</label>
                        <select id="client_state" v-model="client_state">
                            <option v-for="state in allStates" :key="state.name" :value="state.name">
                                {{ state.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="client_rfc">RFC</label>
                        <input type="text" id="client_rfc" placeholder="Ingrese el RFC" v-model="clientDetails.client_rfc" />
                    </div>
                    <div class="form-group">
                        <label for="phone">Teléfono</label>
                        <input type="text" id="phone" placeholder="Ingrese un número de celular" v-model="clientDetails.phone" />
                    </div>
                    <button type="submit" class="btn-submit-save" @click="saveNewClient">Guardar datos del nuevo cliente</button>
                    <button @click="cancelNewClient" class="btn-submit-cancel">Cancelar</button>
                </div>
            </div>

                <!-- Datos del vehículo -->
            <div class="form-section">
                <h3>Datos del vehículo</h3>
                <img src="@/assets/vehicle-icon.png">
                <!-- Formulario de datos del vehículo -->
                <!-- ...campos de vehículo... -->
                <div class="checkbox-group">
                    <input type="checkbox" id="send-to-lot" v-model="sendToLot" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <label for="send-to-lot">Enviar a lote</label>
                </div>
                <div class="form-group">
                    <label for="vehicle_brand">Marca</label>
                    <select v-model="localNoteDetails.vehicle_brand" @change="FetchFilteredSubBrands" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option v-for="(brand, pk) in brands" :key="brand" :value="brand">
                            {{ pk }}
                        </option>
                    </select>                
                </div>
                <div class="form-group">
                    <label for="vehicle_subbrand">Submarca</label>
                    <select v-model="localNoteDetails.vehicle_sub_brand" :disabled="blockEdit || subBrandLoading"
                        :class="{ 'grayed-out': blockEdit, 'sub-brand-loading-grayed-out': subBrandLoading }">
                        <option v-for="(sub_brand, id) in sub_brands" :key="sub_brand" :value="sub_brand">
                            {{ id }}
                        </option>
                    </select>                
                </div>
                <div class="form-group">
                    <label for="vehicle_model">Modelo</label>
                    <input type="text" id="vehicle_model" placeholder="Si desconoce, deje vacío. (Formato YYYY)" v-model="localNoteDetails.vehicle_model" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group">
                    <label for="vehicle_plates">Placas</label>
                    <input type="text" id="vehicle_plates" placeholder="Ingrese las placas" v-model="localNoteDetails.vehicle_plates" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="vehicle_engine_series">Serie del motor</label>
                    <input type="text" id="vehicle_engine_series" placeholder="Ingrese la serie del motor" v-model="localNoteDetails.vehicle_engine_series" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="vehicle_color">Color</label>
                    <input type="text" id="vehicle_color" placeholder="Ingrese el color" v-model="localNoteDetails.vehicle_color" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="insurance_policy">Póliza</label>
                    <input type="text" id="insurance_policy" placeholder="Ingrese la póliza" v-model="localNoteDetails.insurance_policy" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="vehicle_owner_name">Dueño (Asegurado)</label>
                    <input type="tel" id="vehicle_owner_name" placeholder="Ingrese el nombre del asegurado" v-model="localNoteDetails.vehicle_owner_name" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="file_number">Expediente</label>
                    <input type="tel" id="file_number" placeholder="Ingrese el expediente" v-model="localNoteDetails.file_number" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="file_number">Aprobado por:</label>
                    <input type="tel" id="approved_by" placeholder="Ingrese el nombre de quien aprueba" v-model="localNoteDetails.approved_by" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="sendToLot">
                    <label for="???">Lote</label>
                    <select type="tel" id="payment_method" v-model="localNoteDetails.lot">
                        <option value="LOTE SANTA CATARINA">LOTE SANTA CATARINA</option>
                        <option value="LOTE SAN ROBERTO">LOTE SAN ROBERTO</option>
                        <option value="LOTE SAN RAFAEL">LOTE SAN RAFAEL</option>
                        <option value="LOTE SAN CHINA">LOTE CHINA</option>
                        <option value="RESGUARDO">RESGUARDO</option>
                    </select>                </div>
                <div class="form-group" v-show="sendToLot">
                    <label for="???">Retención</label>
                    <select type="tel" id="payment_method" v-model="localNoteDetails.lot_retention">
                        <option value="A DISPOSICION DE SU DUEÑO">A DISPOSICION DE SU DUEÑO</option>
                        <option value="ABANDONO">ABANDONO</option>
                        <option value="ACCIDENTE">ACCIDENTE</option>
                        <option value="ATROPELLO">ATROPELLO</option>
                        <option value="EMBARGO">EMBARGO</option>
                        <option value="INFRACCIONES">INFRACCIONES</option>
                        <option value="INVESTIGACIONES">INVESTIGACIONES</option>
                        <option value="OPERATIVO AA">OPERATIVO AA</option>
                    </select>
                </div>
                <div class="form-group" v-show="sendToLot">
                    <label for="???">Autoridad</label>
                    <input type="tel" id="???" placeholder="Ingrese la autoridad" v-model="localNoteDetails.lot_authority"/>
                </div>
            </div>

            <!-- Datos de ubicación -->
            <div class="form-section">
                <h3>Ubicación de Origen</h3>
                <img src="@/assets/ubi-icon.png">
                <!-- Formulario de datos de ubicación -->
                <!-- ...campos de ubicación... -->
                <div class="checkbox-group">
                    <input type="checkbox" id="toggleFields" v-model="showOptionalFields" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <label for="toggleFields">Servicio carretero</label>
                </div>
                <div class="form-group" >
                    <label for="source_street">Calle</label>
                    <input type="text" id="source_street" v-model = "localNoteDetails.source.street_name" placeholder="Ingrese la calle principal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_number">Número</label>
                    <input type="text" id="source_number" v-model = "localNoteDetails.source.number" placeholder="Ingrese el número" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_inbtw">Calles Adicionales</label>
                    <input type="text" id="source_inbtw" v-model = "localNoteDetails.source.between_streets" placeholder="Ingrese entre que calles se encuentra" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_neighborhood">Colonia</label>
                    <input type="text" id="source_neighborhood" v-model = "localNoteDetails.source.neighborhood" placeholder="Ingrese la colonia" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" >
                    <label for="source_postal_code">C.P (Código Postal)</label>
                    <input type="text" id="source_postal_code" v-model = "localNoteDetails.source.zip_code" placeholder="Ingrese el código postal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_municipality">Municipio</label>
                    <input type="text" id="source_municipality" v-model = "localNoteDetails.source.city"  placeholder="Ingrese el municipio" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_state">Estado</label>
                    <select id="source_state" v-model="localNoteDetails.source.state" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option v-for="state in allStates" :key="state.name" :value="state.name">
                            {{ state.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="source_lat">Latitud</label>
                    <input type="tel" id="source_lat" v-model="localNoteDetails.source.lat" placeholder="Ingrese la latitud" @keypress="validateFloatField" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="source_long">Longitud</label>
                    <input type="tel" id="source_long" v-model="localNoteDetails.source.lon" placeholder="Ingrese la longitud" @keypress="validateFloatField" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="source_km">KM (Solo si es en carretera)</label>
                    <input type="tel" id="source_km" v-model = "localNoteDetails.source_km" placeholder="Ingrese el KM de la carretera" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group">
                    <label for="source_notes">Referencias</label>
                    <textarea id="source_notes" rows="5" v-model = "localNoteDetails.source_notes" placeholder="Ingrese información adicional" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"></textarea>
                </div>  
            </div>

            <div class="form-section">
                <h3>Ubicación de Destino</h3>
                <img src="@/assets/ubi-des-icon.png">
                <!-- Formulario de datos de ubicación -->
                <!-- ...campos de ubicación... -->
                <div class="checkbox-group">
                    <input type="checkbox" id="toggleFields1" v-model="showOptionalFields1" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <label for="toggleFields1">Servicio carretero</label>
                </div>
                <div class="form-group" >
                    <label for="source_street">Calle</label>
                    <input type="text" id="source_street" v-model= "localNoteDetails.destination.street_name" placeholder="Ingrese la calle principal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_number">Número</label>
                    <input type="text" id="source_number" v-model = "localNoteDetails.destination.number" placeholder="Ingrese el número" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_inbtw">Calles Adicionales</label>
                    <input type="text" id="source_inbtw" v-model= "localNoteDetails.destination.between_streets" placeholder="Ingrese entre que calles se encuentra" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_neighborhood">Colonia</label>
                    <input type="text" id="source_neighborhood" v-model = "localNoteDetails.destination.neighborhood" placeholder="Ingrese la colonia" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" >
                    <label for="source_postal_code">C.P (Código Postal)</label>
                    <input type="text" id="source_postal_code" v-model="localNoteDetails.destination.zip_code" placeholder="Ingrese el código postal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_municipality">Municipio</label>
                    <input type="text" id="source_municipality" v-model="localNoteDetails.destination.city" placeholder="Ingrese el municipio" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="destination_state">Estado</label>
                    <select id="destination_state" v-model="localNoteDetails.destination.state" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option v-for="state in allStates" :key="state.name" :value="state.name">
                            {{ state.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="source_lat">Latitud</label>
                    <input type="tel" id="source_lat" v-model="localNoteDetails.destination.lat" placeholder="Ingrese la latitud" @keypress="validateFloatField" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="source_long">Longitud</label>
                    <input type="tel" id="source_long" v-model="localNoteDetails.destination.lon" placeholder="Ingrese la longitud" @keypress="validateFloatField" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="source_km">KM (Solo si es en carretera)</label>
                    <input type="tel" id="source_km" v-model="localNoteDetails.destination_km" placeholder="Ingrese el KM de la carretera" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group">
                    <label for="source_notes">Referencias</label>
                    <textarea id="source_notes" rows="5" v-model="localNoteDetails.destination_notes" placeholder="Ingrese información adicional" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div v-if="currentForm === 'assignment'">
        <div class="form-container">
            <div class="form-section">
                <div class="time-data">
                    <h3>Datos de tiempo</h3>
                    <img src="@/assets/time-icon.png">
                
                    <div v-for="(assignationData, index) in assignationsData" :key="index">
                        <div class="void"></div>
                        <div class="form-group">
                            <div><b>Unidad:</b> {{ assignationData.unidad }}</div>
                            <div><b>Operador:</b> {{ assignationData.operador }}</div>
                            <label>Fecha y Hora de Inicio</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.start_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.start_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Arribo</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.arrival_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.arrival_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Contacto</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.contact_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.contact_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Termino</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.end_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.end_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <div class="void"></div>
                            <button class="btn-submit-save" @click="saveAssignation(assignationData,index)" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Actualizar</button>
                            <div class="void"></div>
                            <div class="form-group-final"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3>Asignación de unidad y operador</h3>
                <img src="@/assets/operator-assignament-icon.png">
                <div class="map-container">
                    <div class="map">
                            <Map
                            :center="mapCenter"
                            :zoom="10"
                            map-type-id="roadmap"
                            style="width: 40vw; height: 50vh"
                            >
                            <!-- Display blue markers for vehicle locations -->
                            <Marker
                                v-for="location in filteredBlueLocations"
                                :key="'blue_' + location.vehicle_plate"
                                :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
                                :icon="require('@/assets/grua-map-icon-marker-gray.png')"
                                :options="{ zIndex: 5 }" 
                                @click="openInfoWindow(location)"
                            />

                            <!-- Display red marker at a specified location -->
                            <Marker
                                v-if="showRedMarker"
                                :position="this.redMarkerPosition.value"
                                :icon="require('@/assets/red-marker.png')"
                                :options="{ zIndex: 99999 }" 
                            />

                            <!-- Display green markers for specific vehicle locations -->
                            <Marker
                                v-for="location in filteredGreenLocations"
                                :key="'green_' + location.vehicle_plate"
                                :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
                                :icon="require('@/assets/grua-map-icon-marker.png')"
                                :options="{ zIndex: 9999 }" 
                                @click="openInfoWindow(location)"
                            />

                        <!-- InfoWindow component -->
                        <InfoWindow
                            :options="{ content: infoContent }"
                            :position="infoPosition"
                            :opened="infoWindowOpened"
                            @closeclick="infoWindowOpened = false"
                        />
                        </Map>
                </div>
            </div>
        </div>
        <div class="form-section">
            <h3>Sugerencias</h3>
            <table>
                <tr>
                    <th>Recomendación</th>
                    <th>Número económico</th>
                    <th>Tiempo estimado de arribo (min)</th>
                    <th>Seleccionar</th>
                </tr>
                <tr>
                <td>1</td>
                <td>{{ this.recommendations[0]['unit'] }}</td>
                <td>{{ this.recommendations[0]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[0]['unit'])" :disabled="buttonClicked['btn1']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[0])" :class="{ 'grayed-out': buttonClicked['btn1']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[0]) }">Elegir</button></td>
            </tr>
            <tr>
                <td>2</td>
                <td>{{ this.recommendations[1]['unit'] }}</td>
                <td>{{ this.recommendations[1]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[1]['unit'])" :disabled="buttonClicked['btn2']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[1])" :class="{ 'grayed-out': buttonClicked['btn2']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[1]) }">Elegir</button></td>
            </tr>
            <tr>
                <td>3</td>
                <td>{{ this.recommendations[2]['unit'] }}</td>
                <td>{{ this.recommendations[2]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[2]['unit'])" :disabled="buttonClicked['btn3']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[2])" :class="{ 'grayed-out': buttonClicked['btn3']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[2]) }">Elegir</button></td>
            </tr>
            <tr>
                <td>4</td>
                <td>{{ this.recommendations[3]['unit'] }}</td>
                <td>{{ this.recommendations[3]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[3]['unit'])" :disabled="buttonClicked['btn4']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[3])" :class="{ 'grayed-out': buttonClicked['btn4']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[3]) }">Elegir</button></td>
            </tr>
            <tr>
                <td>5</td>
                <td>{{ this.recommendations[4]['unit'] }}</td>
                <td>{{ this.recommendations[4]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[4]['unit'])" :disabled="buttonClicked['btn5']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[4])" :class="{ 'grayed-out': buttonClicked['btn5']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[4]) }">Elegir</button></td>
            </tr>
            </table>
            <div class="void"></div>
            <form @submit.prevent="guardarDatos">
                <button @click.prevent="agregarCampos" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Agregar más operadores</button>
                <button @click.prevent="fetchRecommendations()" :disabled="blockEdit||!blockRecommend||recommenderLoading" :class="{ 'grayed-out': !blockRecommend||recommenderLoading }">Recomendar</button>
                <button type="button" @click.prevent="eliminarCampos()" class="button-delete-operator" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Eliminar</button>
                <div v-for="(grupo, index) in gruposCampos" :key="index">
                    <div class="form-group">
                        <label :for="'unidad-' + index">Unidad asignada (Número económico)</label>
                        <input type="text" :id="'unidad-' + index" v-model="grupo.unit" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label :for="'operador-' + index">Operador asignado (Número de empleado)</label>
                        <input type="text" :id="'operador-' + index" v-model="grupo.operator" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label :for="'kmInicio-' + index">Km inicio</label>
                        <input type="text" :id="'kmInicio-' + index" v-model.number="grupo.start_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group">
                        <label :for="'kmFin-' + index">Km fin</label>
                        <input type="text" :id="'kmFin-' + index" v-model.number="grupo.end_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group-final"></div>
                </div>
                <button type="submit" class="btn-submit-save" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Guardar</button>
            </form>
        </div>
    </div>
    </div>

    <div v-if="currentForm === 'payment'">
        <div class="form-section">
            <h3>Costos</h3>
            <img src="@/assets/cost-icon.png">
        </div>
        <div class="form-container">
            <div class="form-section">
                    <!-- Formulario de datos de ubicación -->
                    <!-- ...campos de ubicación... -->
                    <div class="form-group">
                        <label for="cost_by_km">Costo por kilómetro</label>
                        <input type="text" id="cost_by_km" placeholder="Ingrese el costo por km" v-model="localNoteDetails.cost_by_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label for="total_kms">Total de kilómetros</label>
                        <input type="text" id="total_kms" placeholder="Ingrese el total de kms" v-model="localNoteDetails.total_kms" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label for="maneuvers">Maniobras</label>
                        <input type="text" id="maneuvers" placeholder="Ingrese las maniobras realizadas" v-model="localNoteDetails.maneuvers" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label for="highway_tolls">Casetas</label>
                        <input type="text" id="highway_tolls" placeholder="Ingrese el número de casetas pagadas" v-model="localNoteDetails.highway_tolls" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label for="subtotal">Subtotal</label>
                        <input type="text" id="subtotal" placeholder="Ingrese el subtotal" v-model="localNoteDetails.subtotal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
            </div>
            <div class="form-section">
                <div class="form-group">
                        <label for="iva">IVA</label>
                        <input type="text" id="iva" placeholder="Ingrese el iva" v-model="localNoteDetails.iva" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label for="retention">Retención</label>
                        <input type="tel" id="retention" placeholder="Ingrese la retención" v-model="localNoteDetails.retention" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label for="total">Total</label>
                        <input type="tel" id="total" placeholder="Ingrese el total" v-model="localNoteDetails.total" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label for="total_ovr">Nuevo final Total</label>
                        <input type="tel" id="total_ovr" placeholder="Ingrese el nuevo final total" v-model="localNoteDetails.total_ovr" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
            </div>
            <div class="form-section">
                <div class="form-group">
                    <label for="payment_method">Método de pago</label>
                    <select id="payment_method" v-model="localNoteDetails.payment_method" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="PPD">PAGO EN PARCIALIDADES O DIFERIDO</option>
                        <option value="PUE">PAGO EN UNA EXHIBICIÓN</option>
                    </select>
                    </div>
                <div class="form-group">
                    <label for="payment_type">Tipo de pago</label>
                    <select id="payment-type" v-model="localNoteDetails.payment_type" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="99" v-if="localNoteDetails.payment_method === 'PPD'">POR DEFINIR</option>
                        <template v-if="localNoteDetails.payment_method !== 'PPD'">
                            <option value="99">POR DEFINIR</option>
                            <option value="04">TARJETA DE CRÉDITO</option>
                            <option value="03">TRANSFERENCIA</option>
                            <option value="01">EFECTIVO</option>
                            <option value="28">TARJETA DE DÉBITO</option>
                            <option value="02">CHEQUE NOMINATIVO</option>
                        </template>
                    </select>
                </div>
                <div class="form-group">
                    <label for="invoice_use">CFDI</label>
                    <select id="invoice_use" v-model="localNoteDetails.invoice_use" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="G01">ADQUISICIÓN DE MERCANCÍAS</option>
                        <option value="G02">DEVOLUCIOINES, DESCUENTOS O BONIFICACIONES</option>
                        <option value="G03">GASTOS EN GENERAL</option>
                        <option value="I01">CONSTRUCCIONES</option>
                        <option value="I02">MOBILIARIO Y EQUIPO DE OFICINA POR INVERSIONES</option>
                        <option value="I03">EQUIPO DE TRANSPORTE</option>
                        <option value="I04">EQUIPO DE CÓMPUTO Y ACCESORIOS</option>
                        <option value="I05">DADOS, TROQUELES, MOLDES, MATRICES Y HERRAMENTAL</option>
                        <option value="I06">COMUNICACIONES TELEFÓNICAS</option>
                        <option value="I07">COMUNICACIONES SATELITALES</option>
                        <option value="I08">OTRA MAQUINARIA Y EQUIPO</option>
                        <option value="D01">HONORARIOS MÉDICOS, DENTALES Y GASTOS HOSPITALARIOS</option>
                        <option value="D02">GASTOS MÉDICOS POR INCAPACIDAD O DISCAPACIDAD</option>
                        <option value="D03">GASTOS FUNERALES</option>
                        <option value="D04">DONATIVOS</option>
                        <option value="D05">INTERESES REALES EFECTIVAMENTE PAGADOS POR CRÉDITOS HIPOTECARIOS</option>
                        <option value="D06">APORTACIONES VOLUNTARIAS AL SAR</option>
                        <option value="D07">PRIMAS POR SEGUROS DE GASTOS MÉDICOS</option>
                        <option value="D08">GASTOS DE TRANSPORTACIÓN ESCOLAR OBLIGATORIA</option>
                        <option value="D09">DEPÓSITOS EN CUENTAS PARA EL AHORRO </option>
                        <option value="D10">PAGOS POR SERVICIOS EDUCATIVOS</option>
                        <option value="S01">SIN EFECTOS FISCALES</option>
                        <option value="CP01">PAGOS</option>
                        <option value="CN01">NÓMINA</option>
                </select>
                </div>
                    <div class="form-group">
                        <label for="payment-notes">Notas del pago</label>
                        <textarea id="payment-notes" rows="5" placeholder="Ingrese notas del pago" v-model="localNoteDetails.payment_notes" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"></textarea>
                    </div>
            </div>
        </div>
    </div>

    <div v-if="currentForm === 'billing'">
        <div class="form-section">
            <h3>Facturación</h3>
            <img src="@/assets/cost-icon.png">
        </div>
        <div class="form-container">
            
            <div class="form-section">
                <div class="form-group">
                    <label for="mercancia_descripcion">Descripción de mercancía</label>
                    <input type="text" id="mercancia_descripcion" placeholder="Ingrese la descripción de mercancía" v-model="localNoteDetails.mercancia_descripcion" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
            </div>
            <div class="form-section">
                <div class="form-group">
                    <label for="mercancia_valor">Valor del vehículo</label>
                    <input type="text" id="mercancia_valor" placeholder="Ingrese el valor del vehículo" v-model="localNoteDetails.mercancia_valor" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
            </div>
            <div class="form-section">
                <div class="checkbox-group">
                    <input type="checkbox" id="toggleFields1" v-model="cartaporte" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <label for="toggleFields1">Generar carta porte</label>
                </div>
                <div class="form-group">
                    <label for="unit_inventory">UNIDADES DE MEDIDA DE LA MERCANCÍA</label>
                    <select id="unit_inventory" v-model="unit_inventory" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                </select>
                </div>
            </div>
        </div>
        <button class="btn-submit-savebilling-edit-assistance" @click="generateInvoiceDocument">Generar factura</button>
    </div>

    <div v-if="currentForm === 'images'">
    <div class="form-section">
      <h3>Evidencia del siniestro</h3>
      <img src="@/assets/images-section.png">
    </div>
    <div class="form-container">
      <div class="form-section-images" v-for="section in imageSections" :key="section.loc">
        <div class="form-group-images">
          <label>{{ section.label }}</label>
        </div>
        <div class="image-container" v-if="section.images.default">
          <img :src="section.images.default">
        </div>
        <div class="image-container-upload" v-for="(image, index) in section.images.uploaded" :key="index">
          <div class="image-wrapper">
            <img :src="image">
            <button class="delete-button" @click="deleteImage(section, index)">x</button>
          </div>
        </div>
        <button class="btn-submit-upload-images" @click="uploadFile(section)"> Subir imágen</button>
        <button class="btn-submit-download-images" @click="downloadAllImages(section)"> Descargar imágenes</button>
      </div>
    </div>
  </div>

    <button type="submit" class="btn-submit-save-edit-assistance" @click="saveNote" :disabled="blockEdit||!isFormValid||saving_note" :class="{ 'grayed-out-missing-input':!isFormValid,'grayed-out': blockEdit, 'saving-note':saving_note }">Guardar</button>
    <button type="button" class="btn-submit-back" onclick="history.back()">Regresar</button>
    <button type="submit" class="btn-submit-complete" @click="completeNote" v-if="localNoteDetails.status !== 4 && allTimesCompleted">
        Completar nota
    </button>
    <button type="submit" class="btn-submit-close" @click="cancelNote" v-if="localNoteDetails.status !== 2">Cancelar nota</button>
    <button type="submit" class="btn-submit-reOpen" @click="reOpenNote" v-if="localNoteDetails.status === 2 || localNoteDetails.status === 4">
        Reabrir nota
    </button>
</template>

<style scoped>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.create-order{
    display: flex;
    justify-content: space-between; /* Alinea los elementos hijos al final del contenedor */
    align-items: center; /* Centra los elementos hijos verticalmente */
    height: 10vh;
    border-bottom: 1px solid black;
    margin-left: 20px;
    margin-right: 20px;
}
.create-order button {
    padding: 5px;
    background-color: rgb(0, 17, 255);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 3vh;
}
.order-type{
    display: flex;
    margin-top: 5vh;
}
.form-group {
    margin-bottom: 15px;
    width: 10vw;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="text"],
input[type="tel"] {
    width: 20vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-submit-save {
    background-color: #00ff1e; /* Color del botón de enviar */
    color: #fff; /* Color del texto del botón */
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-cancel {
    background-color: #ff0000; /* Color del botón de enviar */
    color: #fff; /* Color del texto del botón */
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.form-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}

.form-section, .form-section-images{
    flex-basis: calc(33% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 10px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
}

.form-section-images img{
    width: 100px;
    height: 100px;
}

.image-container {
    margin-top: 10vh;
    width: 100%;
    height: 200px;
}

.image-container-upload {
    margin-top: 2vh;
    margin-bottom: 3vh;
    width: 100%;
    height: 200px;
}

.form-group-images{
    height: 8vh;
}

.image-container-upload img{
    width: 200px !important;
    height: 200px !important;
}


.form-section:last-child {
    margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
}

h2 {
    margin-bottom: 20px; /* Espacio debajo de los títulos de sección */
}
.form-group label {
    text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
    font-weight: bold; /* Hace que el texto de la etiqueta sea negrita */
    display: inline-block; /* Hace que la etiqueta sea en bloque pero alinee su contenido */
    width: 100%; /* Asegura que la etiqueta ocupe todo el ancho disponible */
}
.form-section img{
    width: 40px;
    height: 40px;
}

.map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    padding: 20px;
}
.map {
    width: 40vw; /* Ancho inicial para pantallas grandes */
    height: 50vh; /* Altura inicial para pantallas grandes */
}
table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

th {
  background-color: #000000;
  color: #FFC434;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}

button {
    color: #ffffff;
    background-color: #FFC434;
    border: none;
    border-radius: 5px;
  /* Añadir estilos al botón si es necesario */
}
.void{
    height: 30px;
}
.time-data{
    display: grid;
    place-items: center;
}
textarea {
    width: 20vw; /* El mismo ancho que tus inputs */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    resize: vertical; /* Permite al usuario cambiar el tamaño verticalmente, pero no horizontalmente */
}
.checkbox-group {
    display: flex;
    justify-content: flex-start; 
    gap: 5px; 
    font-weight: bold;
}
.checkbox-group input{
    margin-bottom: 1vh;
}
.button-delete-operator{
    background-color: #ff0000;
    color: white;
    margin-left: 3vw;
}
.form-group .client-dropdown {
    position: absolute;
    z-index: 1000;
    width: 20vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto; 
}
.client-dropdown div {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}
.client-dropdown div:hover {
    background-color: #f2f2f2;
}
.form-group select {
    width: 20vw; 
    padding: 8px; 
    margin-bottom: 10px; 
    border: 1px solid #ccc;
    border-radius: 4px; 
    background-color: white; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); 
    background-repeat: no-repeat;
    background-position: right 8px top 50%; 
    background-size: 12px; 
}
.form-group-final{
    margin-bottom: 2vh;
    border-bottom: 1px solid black;
}
.grayed-out {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
}
.grayed-out-missing-input {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.grayed-out-missing-input::after {
    content: "Los campos: Cliente, Marca, Submarca, Latitud y Longitud de origen, Latitud y Longitud de destino son obligatorios"; /* Text to show on mouse hover */
    position: absolute;
    top: -50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease;
}

.grayed-out-missing-input:hover::after {
    opacity: 1; /* Show the text on hover */
}
.saving-note {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.saving-note::after {
    content: "Espere a que se guarde la nota"; /* Text to show on mouse hover */
    position: absolute;
    top: -50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease;
}

.saving-note:hover::after {
    opacity: 1; /* Show the text on hover */
}
.text-color-for-date{
    color: rgb(21, 0, 255);
}
.btn-submit-save-edit-assistance {
    background-color: #00ff1e; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-savebilling-edit-assistance{
    background-color: #5d5d5d; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
}
.btn-submit-back {
    background-color: #ff7700; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-complete {
    background-color: #00eeff; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-close {
    background-color: #ff0000; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-reOpen {
    background-color: #0000ff; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.btn-submit-upload-images{
    background-color: #b1d0ff; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-submit-download-images{
    margin-top: 2vh;
    background-color: #1d0061; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
@media screen and (min-height: 930px){
    .create-order{
        display: flex;
        justify-content: space-between; /* Alinea los elementos hijos al final del contenedor */
        align-items: center; /* Centra los elementos hijos verticalmente */
        height: 10vh;
        border-bottom: 1px solid black;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 6vh;
    }
}

.form-section-images {
  margin-bottom: 20px;
  clear: both;
}

.form-group-images {
  padding-bottom: 40px; /* Increased padding to ensure clear separation */
}

.image-container-upload {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-wrapper {
  position: relative;
  display: inline-block;
  margin: 10px; /* Ensure separation between images */
  vertical-align: top;
}

.image-wrapper img {
  display: block;
  max-width: 100%; /* Ensure the image doesn't overflow its container */
  border: 1px solid #ddd; /* Optional: add border for better visibility */
  border-radius: 4px; /* Optional: add border radius for better aesthetics */
  padding: 5px; /* Optional: add padding for better spacing */
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

.btn-submit-upload-images,
.btn-submit-download-images {
  display: block;
  margin-top: 10px;
}

</style>

<script>
import { ref, reactive, onMounted, nextTick, computed } from 'vue'
import { Map, Marker, InfoWindow } from '@fawmi/vue-google-maps'
import axios from 'axios';
import Swal from 'sweetalert2';
import Compressor from 'compressorjs';

export default {
    props: ['noteDetails', 'assignations','user_id'],
    components: {
        Map,
        Marker,
        InfoWindow
    },
    data() {
        
        const locations = ref([])
        const mapCenter = ref({ lat: 25.66831199290133, lng: -100.2802354639708 })
        const infoWindowOpened = ref(false);
        const infoContent = ref('');
        const infoPosition = reactive({ lat: 0, lng: 0 });
        const redMarkerPosition = ref({ lat: 25.689214, lng: -100.314343 }); // Red marker position DESTINATION
        const showRedMarker = ref(true); // Variable to control the red marker visibility

        // Vector of vehicle names for green markers
        const greenVehicleNames = ref(['254']); // Example vehicle names
        //console.log(greenVehicleNames.value);

        const fetchLocations = () => {
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/fetch-last-vehicle-locations`)
                .then(response => {
                    locations.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        }

        onMounted(() => {
            fetchLocations();
            setInterval(fetchLocations, 20000);
        })

        const openInfoWindow = (location) => {
            infoWindowOpened.value = false;
            infoContent.value = `
                <div>
                <h1>${location.vehicle_name}</h1>
                <p>Placas: ${location.vehicle_plate}</p>
                <p>Velocidad Actual: ${location.vehicle_speed} km/h</p>
                </div>
            `;
            infoPosition.lat = parseFloat(location.lat);
            infoPosition.lng = parseFloat(location.long);
            nextTick(() => {
                infoWindowOpened.value = true;
            });
        };

        // Computed property for filtering blue locations
        const filteredBlueLocations = computed(() => {
            return locations.value.filter(location => !this.greenVehicleNames.value.includes(location.vehicle_name));
        });

        // Computed property for filtering green locations
        const filteredGreenLocations = computed(() => {
            return locations.value.filter(location => this.greenVehicleNames.value.includes(location.vehicle_name));
        });

        return {
            imageSections: [
                { loc: 'front', label: 'Fotografías de la parte de enfrente del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'engine', label: 'Fotografías del motor del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'left', label: 'Fotografías del lado izquierdo del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'right', label: 'Fotografías del lado derecho del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'back', label: 'Fotografías de la parte trasera del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'complementary', label: 'Fotografías complementarias del vehículo (Llantas, Cajuela, interior del vehículo, etc.)', images: { default: '', uploaded: [], blob_ids: [] } },
            ],
            buttonClicked: {
                btn1: false,
                btn2: false,
                btn3: false,
                btn4: false,
                btn5: false,
                images_tab : false,
            },
            blockEdit: false, //Linked to the note status, determines wheter the fields are enabled or not 
            localNoteDetails: {},
            greenVehicleNames,
            filteredBlueLocations,
            filteredGreenLocations,
            mapCenter,
            infoWindowOpened,
            infoContent,
            infoPosition,
            openInfoWindow,
            redMarkerPosition,
            showRedMarker,
            client_name: this.noteDetails.client,
            vehicle_brand: this.noteDetails.vehicle_brand,
            vehicle_sub_brand: this.noteDetails.vehicle_sub_brand,
            brands: [],
            sub_brands: [],
            subBrandLoading: true,
            // Since the user is encouraged to not fill this field if they dont know it, handle as needed
            vehicle_model: this.noteDetails.vehicle_model != null? this.noteDetails.vehicle_model.toString(): null,
            vehicle_plates: this.noteDetails.vehicle_plates,
            vehicle_engine_series: this.noteDetails.vehicle_engine_series,
            vehicle_color: this.noteDetails.vehicle_color,
            insurance_policy: this.noteDetails.insurance_policy,          
            vehicle_owner_name: this.noteDetails.vehicle_owner_name,
            file_number: this.noteDetails.file_number,
            source_street: this.noteDetails.source.street_name,
            source_between_streets: this.noteDetails.source.between_streets,
            source_neighborhood: this.noteDetails.source.neighborhood,
            source_street_number: this.noteDetails.source.number,
            source_city: this.noteDetails.source.city,
            source_zip_code: this.noteDetails.source.zip_code,
            source_state: this.noteDetails.source.state,
            source_lat: this.noteDetails.source.lat,
            source_lon: this.noteDetails.source.lon,
            // not all services need source and destination km. Check the current value and adjust the display accordingly
            source_km: this.noteDetails.source_km != null ? this.noteDetails.source_km.toString() : null,
            source_references: this.noteDetails.source_notes,
            destination_street: this.noteDetails.destination.street_name,
            destination_between_streets: this.noteDetails.destination.between_streets,
            destination_neighborhood: this.noteDetails.destination.neighborhood,
            destination_street_number: this.noteDetails.destination.number,
            destination_city: this.noteDetails.destination.city,
            destination_zip_code: this.noteDetails.destination.zip_code,
            destination_state: this.noteDetails.destination.state,
            destination_lat: this.noteDetails.destination.lat,
            destination_lon: this.noteDetails.destination.lon,
            // not all services need source and destination km. Check the current value and adjust the display accordingly
            destination_km: this.noteDetails.destination_km != null ? this.noteDetails.destination_km.toString() : null,
            destination_references: this.noteDetails.destination_notes,
            cost_by_km: this.noteDetails.cost_by_km,
            total_kms: this.noteDetails.total_kms,
            maneuvers: this.noteDetails.maneuvers,
            highway_tolls: this.noteDetails.highway_tolls,
            subtotal: this.noteDetails.subtotal,
            iva: this.noteDetails.iva,
            retention: this.noteDetails.retention,
            total: this.noteDetails.total,
            total_ovr: this.noteDetails.total_ovr,
            payment_method: this.noteDetails.payment_method,
            payment_type: this.noteDetails.payment_type,
            invoice_use: this.noteDetails.invoice_use,
            payment_notes: this.noteDetails.payment_notes,
            // metodo_pago: this.noteDetails.metodo_pago,
            // forma_pago: this.noteDetails.forma_pago,
            // clave_prod_serv: this.noteDetails.clave_prod_serv,
            // concepto_descripcion: this.noteDetails.concepto_descripcion,
            mercancia_descripcion: this.noteDetails.mercancia_descripcion,
            mercancia_valor: this.noteDetails.mercancia_valor,
            approved_by: this.noteDetails.approved_by,
            // mercancia_unidad: this.noteDetails.mercancia_unidad,
            // asegura_resp_civil: this.noteDetails.asegura_resp_civil,
            // poliza_resp_civil: this.noteDetails.poliza_resp_civil,
            // remolque_tipo: this.noteDetails.remolque_tipo,
            currentForm: 'initialData',
            client_state: '',
            allStates: [
                { name: 'Aguascalientes' },
                { name: 'Baja California' },
                { name: 'Baja California Sur' },
                { name: 'Campeche' },
                { name: 'Chiapas' },
                { name: 'Chihuahua' },
                { name: 'Ciudad de México' },
                { name: 'Coahuila' },
                { name: 'Colima' },
                { name: 'Durango' },
                { name: 'Estado de México' },
                { name: 'Guanajuato' },
                { name: 'Guerrero' },
                { name: 'Hidalgo' },
                { name: 'Jalisco' },
                { name: 'Michoacán' },
                { name: 'Morelos' },
                { name: 'Nayarit' },
                { name: 'Nuevo León' },
                { name: 'Oaxaca' },
                { name: 'Puebla' },
                { name: 'Querétaro' },
                { name: 'Quintana Roo' },
                { name: 'San Luis Potosí' },
                { name: 'Sinaloa' },
                { name: 'Sonora' },
                { name: 'Tabasco' },
                { name: 'Tamaulipas' },
                { name: 'Tlaxcala' },
                { name: 'Veracruz' },
                { name: 'Yucatán' },
                { name: 'Zacatecas' }
            ],
            filteredClientStates: [],
            filteredSourceStates: [],
            filteredDestinationStates: [],
            client_address: '',
            client_rfc: '',
            phone:'',
            addNewClient: false,
            clients: [], 
            filteredClients: [], 
            clientDetails: {
                "client_address": {
                    
                }
            }, 
            isClientSelected: false,
            showOptionalFields: false,
            showOptionalFields1: false,
            sendToLot: false,
            gruposCampos: [ 
            { unit: '', operator: '', start_km: null, end_km: null, assistance_note: this.noteDetails.id, assigned_by: this.user_id},
            ],
            assignationsData: [],
            saving_note: false,
            billingInfo: {},
            cartaporte: false,
            unit_inventory:'',
        };
    },
    watch: {
        assignations: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (Array.isArray(newVal) && newVal.length > 0) {
                    this.gruposCampos = newVal.map(assignation => ({
                        id: assignation.id,
                        unit: assignation.unit.unit_short_id,
                        operator: assignation.operator.employee_ident,
                        assigned_by: assignation.assigned_by.employee_user,
                        start_km: assignation.start_km,
                        end_km: assignation.end_km,
                        assistance_note: assignation.assistance_note.id
                    }));
                    } 
        },
        client_state(newValue) {
            this.filteredClientStates = this.getFilteredStates(newValue);
        },
        source_state(newValue) {
            this.filteredSourceStates = this.getFilteredStates(newValue);
        },
        destination_state(newValue) {
            this.filteredDestinationStates = this.getFilteredStates(newValue);
        },
        'localNoteDetails.note_ident': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchAssignationsForNote();
            }
        }
    }},
    mounted() {
        this.fetchAssignationsForNote();
    },
    computed: {
        allTimesCompleted() {
            if (!Array.isArray(this.assignationsData) || this.assignationsData.length === 0) {
                return false;
            }
            return this.assignationsData.every(assignation =>
                assignation.start_time && assignation.arrival_time &&
                assignation.contact_time && assignation.end_time
            );
        },
        isFormValid() {
            /** Used to prevent the form submission until all required values are set */
            return (
                // sub brand, client, and lat lon are required 
                // check all required fields contain data before allowing update of the note
                //Client name being null/empty implies there is nothing in the client field, thus form cannot be valid in this state 
                this.client_name != null &&  this.client_name != "" &&
                this.localNoteDetails.vehicle_brand != null && this.localNoteDetails.vehicle_brand != "" &&
                this.localNoteDetails.vehicle_sub_brand != null && this.localNoteDetails.vehicle_sub_brand != "" &&
                this.localNoteDetails.source.lat != "" &&  this.localNoteDetails.source.lat !== null &&
                this.localNoteDetails.source.lon != "" &&  this.localNoteDetails.source.lat !== null &&
                this.localNoteDetails.destination.lat !== "" && this.localNoteDetails.destination.lat != null &&
                this.localNoteDetails.destination.lon !== "" && this.localNoteDetails.destination.lon != null
            );
        }
    },
    methods: {
        async fetchClientById(clientId) {
            try {
                const apiDirectory = process.env.API_DIRECTORY; // Adjust to your actual API endpoint
                const response = await axios.get(`${apiDirectory}/clients/${clientId}/`);
                this.client_id = clientId; // Keep the client ID in sync
                this.client_name = response.data.client_name; // Update the display name
                // No need to update localNoteDetails.client here, as it should already have the correct ID
                //console.log(this.client_name)
            } catch (error) {
                console.error('Error fetching client details:', error);
            }
        },
        async fetchClients() {
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-privileged-clients`);
                this.clients = response.data;
            } catch (error) {
                // error
            }
        },
        filterClients() {
            if (this.client_name.length >= 0 && !this.isClientSelected) {
                const searchTerm = this.client_name.toLowerCase();

                this.filteredClients = this.clients.filter(client => {
                    const clientName = client.client_name.toLowerCase();
                    return clientName.includes(searchTerm);
            });
            } else {
                this.filteredClients = [];
            }
        },
        selectClient(client) {
            this.clientDetails = { ...client };
            this.client_name = client.client_name;
            this.client_id = client.id;
            this.localNoteDetails.client = this.client_id;
            this.filteredClients = [];
            this.isClientSelected = true;
        },
        toggleNewClientForm(){
            this.addNewClient = true;
            if (this.addNewClient) {
                this.isClientSelected = false;
            }
        },
        cancelNewClient() {
            this.addNewClient = false; 
        },
        closeDropdown() {
            this.filteredClients = [];
        },
        filterStates() {
            if (!this.searchState) {
                this.filteredStates = this.states;
            } else {
                this.filteredStates = this.states.filter((state) =>
                    state.toLowerCase().includes(this.searchState.toLowerCase())
            );
            }
            this.isStateDropdownActive = true;
        },
        getFilteredStates(searchValue) {
            if (searchValue.length < 0) {
                return [];
            }
            return this.allStates.filter(state =>
                state.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        },
        selectClientState(name) {
            this.client_state = name;
            this.filteredClientStates = [];
        },
        selectSourceState(name){
            this.source_state = name;
            this.filteredSourceStates = [];
        },
        selectDestinationState(name){
            this.destination_state = name;
            this.filteredDestinationStates = [];
        },
        agregarCampos() {
            this.gruposCampos.push({ unit: '', operator: '', start_km: 0, end_km: 0, assistance_note: this.noteDetails.id, assigned_by: this.user_id});
        },
        async guardarDatos() {
            const apiDirectory = process.env.API_DIRECTORY;
            //Call the unit assignations endpoint to check if any of the units that is about to be assigned is in hard busy state
            let units = [];
            let response;
            const busyUnits = {};
            for (let obj of this.gruposCampos) {
                let unitValue = obj['unit'];
                units.push({ 'unit': unitValue });
            }
            //console.log(units)
            try {
                response = await axios.post(`${apiDirectory}/get-unit-assignations`, units);
                // This iteration ensures even if the response contains duplicates, they are removed 
                response.data.forEach(item => {
                    if (item.status === "Ocupada") {
                        busyUnits[item.unit_short_id] = item.status;
                    }
                });
            }
            catch (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Al menos una de las unidades que proporcionó no existen.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                } else if (error.response && error.response.status === 400) {
                    console.error("Bad request", error)
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Por favor intentelo de nuevo. Si el error persiste, contacte a su administrador.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                } else if (error.response && error.response.status === 500) {
                    console.error("Internal server error", error)
                    Swal.fire({
                        title: '¡Error interno del servidor!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Por favor intentelo de nuevo. Si el error persiste, contacte a su administrador.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                } else {
                    console.error("Uncaught error", error)
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Verifique que la información que introdujo sea válida',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                }
            }
            // After the unit status have been succesfully fetched, warn the user if there is a hard busy unit, if its not the case, or they 
            // ack the warning, continue, else stop
            if (Object.keys(busyUnits).length > 0) {
                // Construct message for SweetAlert
                let message = "Al menos una de las unidades que está asignando ya se encuentra ocupada.\n\n";
                message += "Unidades Ocupadas:\n";
                for (const unitId in busyUnits) {
                    message += `${unitId}\n`;
                }
                const result = await Swal.fire({
                    title: "¡Advertencia!",
                    text: message,
                    icon: "warning",
                    showCancelButton: true,
                    buttons: {
                        cancel: "Cancelar",
                        confirm: "Continuar"
                    }
                });
                if (!result.isConfirmed) return
            }
            try {
                // Update note status to 5
                const noteID = this.localNoteDetails.note_ident;
                this.localNoteDetails.status = 5;
                this.sanitizeForm();
                await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
            }
            // Update the note status
            catch (error) {
                console.error(error)
                Swal.fire({
                    title: '¡Error!',
                    text: 'Hubo un error al actualizar el estatus de la nota, Por favor intente de nuevo.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        return
                    }
                });
                // Even if the user ignores the popup, exit out of the func
                return
            }
            // Update the note assignations    
            try {
                if (Object.keys(this.assignations).length === 0) {
                    // If assignations is empty, use POST method
                    response = await axios.post(`${apiDirectory}/set-note-assignations`, this.gruposCampos);
                } else {
                    // If assignations is not empty, use PUT method
                    response = await axios.put(`${apiDirectory}/set-note-assignations`, this.gruposCampos);
                }
                Swal.fire({
                    title: '¡Actualizado!',
                    text: 'Las asignaciones se han actualizado con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(); // Reload the page after 'Ok' is clicked
                    }
                });
            } catch (error) {
                // Check if the error is due to DNE 
                if (error.response && error.response.status === 404) {
                    console.error("Not found", error)
                    Swal.fire(
                        '¡Error!',
                        'Verifique el id de las unidades y operadores que intenta asignar. Si esta unidad existía en el pasado, asegurese de que no ha sido eliminada.',
                        'error'
                    );
                } else if (error.response && error.response.status === 400) {
                    console.error("Bad request", error)
                    Swal.fire(
                        '¡Error!',
                        'Verifique que los valores que intenta asignar son correctos.',
                        'error'
                    );
                } else {
                    console.error("Failed to update assignation:", error);
                    Swal.fire(
                        '¡Error!',
                        'Hay errores en las asignaciones',
                        'error'
                    );
                }
            }
        },
        seleccionarOpcion(unit){
            var n = this.assignations.length;
            if(n>0){
                this.agregarCampos()
                n = n+1;
            }
            else{
                n = 1;
            }
            this.gruposCampos[n-1].unit = unit
            this.buttonClicked['btn1'] = true;
            this.buttonClicked['btn2'] = true;
            this.buttonClicked['btn3'] = true;
            this.buttonClicked['btn4'] = true;
            this.buttonClicked['btn5'] = true;
        },
        async eliminarCampos() {
            const n = this.gruposCampos.length;
            if (n > 1) {
                //console.log(this.gruposCampos[n - 1]);
                const apiDirectory = process.env.API_DIRECTORY;
                try {
                    await axios.post(`${apiDirectory}/delete-note-assignations`, this.assignations[n - 1]);
                    Swal.fire({
                        title: '¡Actualizado!',
                        text: 'Las asignaciones se han actualizado con éxito',
                        icon: 'success',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            location.reload(); // Reload the page after 'Ok' is clicked
                        }
                    });
                } catch (error) {
                    Swal.fire({
                        title: '¡Actualizado!',
                        text: 'Las asignaciones se han actualizado con éxito',
                        icon: 'success',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            location.reload(); // Reload the page after 'Ok' is clicked
                        }
                    });
                }
            } else {
                Swal.fire('Error', 'Debe haber al menos un grupo de campos.', 'error');
            }
        },
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        showForm(formName) {
            // To save unnecesary calls to azure, as tradeoff images are loaded on demand 
            if (formName=='images'&this.buttonClicked.images_tab==false){
                this.fetchImages();
            }
            this.currentForm = formName;
        },
        saveNote: async function () {
            try {
                const noteID = this.noteDetails.note_ident; // Ensure this matches the actual ID property
                const apiDirectory = process.env.API_DIRECTORY;
                // check if the note was set to a LOT note, if so, calculate a new id
                if (this.sendToLot) {
                    // Replace the id with the newly calculated lot ident 
                    this.localNoteDetails.note_ident = this.localNoteDetails.note_ident.replace(/^ASV/, 'LOT');                    
                    // Change note type 
                    this.localNoteDetails.type = 4;
                    // Update route to new URI and refresh

                    // Navigate to the new route
                    this.$router.push({
                        name: 'EditNote',
                        params: {
                            notetype: 4,
                            noteID: this.localNoteDetails.note_ident
                        }
                    });

                }

                this.sanitizeForm();
                this.saving_note = true
                await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                Swal.fire(
                    '¡Actualizado!',
                    'Los datos del formulario se han actualizado con éxito.',
                    'success'
                ).then((result) => {
                if (result.isConfirmed) window.location.reload();
                });
                this.saving_note = false;
                this.$forceUpdate;
                // Additional actions after success, e.g., redirecting or updating local state
            } catch (error) {
                // Polygon condition and bad serializer cases
                console.error(error)
                this.saving_note=false
                if (error.response.status == 400) {
                    let isPolygon = false
                    if (error.response.data) {
                        const errorDataString = JSON.stringify(error.response.data);
                        try { isPolygon = errorDataString.includes("Mexico") }
                        catch { isPolygon = false }
                    }
                    if (isPolygon) {
                        Swal.fire('Error', 'Verifique que la latitud y longitud sean válidas. \nLos datos que intentó ingresar no corresponden a una dirección dentro de México.', 'error');
                    }
                    else {
                        Swal.fire('Error', 'Verifique que los datos que está ingresando son válidos', 'error');
                    }
                }
                else {
                    Swal.fire('Error', 'Hubo un problema al actualizar los datos de la nota.', 'error');
                }
            }
        },
        completeNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.status = 4; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while completing the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Completado!',
                    text: 'La nota se ha completado con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al completar la nota.', 'error');
            }
        },
        reOpenNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.status = 1; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while reopening the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Reabierto!',
                    text: 'La nota se ha reabierto con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al reabrir la nota.', 'error');
            }
        },
        cancelNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.status = 2; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while closing the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Cancelado!',
                    text: 'La nota y sus asignaciones se han cancelado con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al cancelar la nota.', 'error');
            }
        },
        saveBilling() {
            // Generate sign and stamp invoice
            const firstAssignation = this.assignationsData[0];
            const noteID = this.localNoteDetails.note_ident; 
            const vectorNoteID = this.localNoteDetails.note_ident.split('-');
            const apiDirectory = process.env.API_DIRECTORY;
            // const subbrandinfo = axios.post(`${apiDirectory}/get-sub-brand`, {"sub_brand_id": firstAssignation.assistance_note.vehicle_sub_brand});
            // console.log(subbrandinfo);
            const distTime = axios.post(`${apiDirectory}/get-distance-and-time`, {"org_lat": this.localNoteDetails.source.lat,
                                                                        "org_lng": this.localNoteDetails.source.lon,
                                                                        "dest_lat": this.localNoteDetails.destination.lat,
                                                                        "dest_lng": this.localNoteDetails.destination.lon});
            distTime.then(response => {
                const distance = response.data.distance;
                this.distance = distance;});
            return axios.get(`${apiDirectory}/get-billing-info/${noteID}/`)
                .then(response => {
                    // this.billingInfo = response.data;
                    // console.log(response.data['client']['tax_regime']);
                    //comenzar diccionario con info
                    this.billingInfo = {
                        cartaporte:this.cartaporte,
                        receptor:{
                            rfc: response.data['client']['client_rfc'], 
                            nombre: response.data['client']['client_name'],
                            uso_cfdi: response.data['invoice_use'],
                            domicilio_fiscal_receptor: response.data['client']['client_address']['codigoPostal'],
                            regimen_fiscal_receptor: response.data['client']['tax_regime']
                        },
                        metodo_pago: response.data['payment_method'],
                        forma_pago: response.data['payment_type'], 
                        serie: vectorNoteID[0], 
                        folio: vectorNoteID[2], 
                        conceptos:{ 
                            clave_prod_serv:'78101803',
                            cantidad:'1',
                            clave_unidad:'ACT',    
                            descripcion: 'TRAFICO',
                            valor_unitario: response.data['subtotal'],
                            objeto_imp: '02', 
                            no_identificacion: '8635',
                            impuestos:{
                                traslados:{
                                    impuesto:'002',
                                    tipo_factor:'Tasa',
                                    tasa_o_cuota:'0.16',
                                    importe: response.data['iva'],
                                },
                                retenciones:{
                                    impuesto:'002',
                                    tipo_factor:'Tasa',
                                    tasa_o_cuota:'0.04',
                                    importe: response.data['retention']
                                }
                            },
                        },
                        complemento:{
                            transp_internac:"No",
                            ubicacion_origen:{
                                tipo_ubicacion:"Origen",
                                id_ubicacion:"OR008635", 
                                domicilio:{
                                    calle: response.data['source']['street_name'],
                                    estado:response.data['source']['state'],
                                    pais:'MEX',
                                    codigo_postal: response.data['source']['zip_code'] 
                                }
                            },
                            ubicacion_destino:{
                                tipo_ubicacion:"Destino",
                                id_ubicacion:"DE008635",
                                domicilio:{
                                    calle: response.data['destination']['street_name'],
                                    estado:response.data['destination']['state'],
                                    pais:'MEX',
                                    codigo_postal: response.data['destination']['zip_code'] 
                                },
                                distancia_recorrida: this.distance 
                            },
                            mercancia:{
                                ///////////////////////////////////////////////////////
                                peso_bruto_total: '4000.000', //subbrandinfo.weight,
                                //////////////////////////////////////////////////////
                                unidad_peso:'KGM',
                                num_total_mercancias:1,
                                bienes_transp:"25101503", 
                                descripcion: this.localNoteDetails.mercancia_descripcion,
                                cantidad:"1.00",
                                clave_unidad: this.unit_inventory, 
                                peso_en_kg: "4000.000",  //subbrandinfo.weight,
                                ///////////////////////////////////////////////////////
                                dimensiones:"300/220/180cm",  //subbrandinfo.dimensions,
                                ////////////////////////////////////////////////////////
                                valor_mercancia: this.localNoteDetails.mercancia_valor,
                                moneda:'MXN',
                                autotransporte:{
                                    perm_sct:"TPAF04",
                                    num_permiso_sct: firstAssignation.unit.sct_permission,
                                    ////////////////////////////////////////////
                                    config_vehicular:"OTROEVGP", //firstAssignation.unit.vehicular_configuration,
                                    ////////////////////////////////////////////
                                    peso_bruto_vehicular: firstAssignation.unit.gross_weight,
                                    placa: firstAssignation.unit.unit_plates,
                                    anio_modelo_vm: firstAssignation.unit.unit_model,
                                    asegura_resp_civil: firstAssignation.unit.civil_liability_insurance, 
                                    /////////////////cambiar 'inciso' por '-'//////////////////////////////
                                    poliza_resp_civil:"640695851-15", //firstAssignation.unit.civil_liability_policy
                                    ///////////////////////////////////////////////////////////////////////////
                                    ///////////////////////Poner selector con catalogo////////////////////////////////////////
                                    sub_tipo_rem:"CTR016", //#grua | caja cerrad: CTR004, seca: CTR007 //firstAssignation.unit.trailer_sub_type
                                    ///////////////////////////////////////////////////////////////////////////
                                },
                            },
                            figura_transporte:{
                                tipo_figura:'01',
                                ////////////////Poner sin guiones//////////////////////////////////
                                rfc_figura: "EAPR9508168MA", //firstAssignation.operator.employee_rfc,
                                ////////////////////////////////////////////////////////////////
                                num_licencia: firstAssignation.operator.employee_license_number,
                                nombre_figura: firstAssignation.operator.employee_first_name + ' ' + firstAssignation.operator.employee_last_name,
                                domicilio:{
                                    calle: firstAssignation.operator.employee_street,
                                    /////////////Poner un catalogo en la vista para agregar/////////////////////////
                                    estado:"Nuevo León", //firstAssignation.operator.employee_municipality,
                                    ////////////////////////////////////////////////////////////////////////////////
                                    pais:'MEX',
                                    codigo_postal: firstAssignation.operator.employee_postcode
                                }
                            }
                        }
                    };
                    return this.invoiceStamping(this.billingInfo);
                })
                .then(stampedInvoice => {
                    return stampedInvoice;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        },
        invoiceStamping(billingInfo) {
            const apiDirectory = process.env.API_DIRECTORY;
            return axios.post(`${apiDirectory}/invoice-stamping`, billingInfo)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        },
        async generateInvoiceDocument(){
            const apiDirectory = process.env.API_DIRECTORY;
            const signedXML = await this.saveBilling();
            const firstAssignation = this.assignationsData[0];
            const dateDifferenceHours = Math.ceil(Math.abs(new Date(firstAssignation.end_time) - new Date(firstAssignation.start_time)) / (1000 * 60 * 60));
            console.log(dateDifferenceHours);
            axios.post(`${apiDirectory}/generate-invoice-documents`, 
                        {signedXML: signedXML,
                        plh_authorize: this.localNoteDetails.approved_by,
                        plh_operatorName: firstAssignation.operator.employee_first_name + ' ' + firstAssignation.operator.employee_last_name,
                        plh_operatorNumber: firstAssignation.operator.employee_ident,
                        plh_orderId: this.localNoteDetails.note_ident,
                        plh_unitNumber: firstAssignation.unit.unit_short_id,
                        plh_plates: this.localNoteDetails.vehicle_plates,
                        plh_vehicleBrand: this.localNoteDetails.vehicle_brand,
                        plh_vehicleModel: this.localNoteDetails.vehicle_model + '  ' + this.localNoteDetails.vehicle_color,
                        plh_niv: this.localNoteDetails.vehicle_engine_series,
                        plh_insuredName: this.localNoteDetails.vehicle_owner_name,
                        plh_record: this.localNoteDetails.file_number,
                        plh_insurancePolicy: this.localNoteDetails.insurance_policy,
                        plh_event: this.localNoteDetails.insurance_incident,
                        plh_origin: this.localNoteDetails.source.neighborhood + ', ' + this.localNoteDetails.source.city + ' ' + this.localNoteDetails.source.state,
                        plh_serviceDescription: ' ',
                        plh_startTime: firstAssignation.start_time,
                        plh_arrivalTime: firstAssignation.arrival_time,
                        plh_endTime: firstAssignation.end_time,
                        plh_retention:this.localNoteDetails.retention,
                        plh_totalTime: dateDifferenceHours
                        }, 
                {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'factura_multiformato.zip');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('Error generating files', error)
            });
        },
    
        fetchLocations() {
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/fetch-last-vehicle-locations`)
                .then(response => {
                    this.locations.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        },
        onMounted() {
            this.fetchLocations();
            setInterval(this.fetchLocations, 20000);
        },
        async saveNewClient() {
            const clientData = {
                client_ident: 'test',
                client_name: this.client_name,
                client_address: {
                    street: this.clientDetails.client_address.street,
                    codigoPostal: this.clientDetails.client_codigoPostal,
                    neighborhood: this.client_neighborhood,
                    city: this.client_city,
                    state: this.client_state,
                },
                client_rfc: this.clientDetails.client_rfc,
                phone: this.clientDetails.phone,
                client_status: 1,
                is_privileged: false
                
            };
            try {
                // Validate clientData here (optional)

                // Replace 'http://yourbackend.api/clients' with your actual API endpoint
                //console.log(clientData);
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/create-client`, clientData, {
                            headers:
                            {
                                'Content-Type': 'application/json',
                            },
                        });
                this.client_id = response.data.id;
                this.localNoteDetails.client = this.client_id;
                
                // If the request is successful
                Swal.fire('Guardado!', 'El nuevo cliente se guardó correctamente.', 'success');
                

            } catch (error) {
                console.error('There was an error saving the client:', error);
                Swal.fire('Error', 'Hubo un problema al guardar el cliente nuevo.', 'error');
            }
        },
        fetchAssignationsForNote() {
            const apiDirectory = process.env.API_DIRECTORY;
            const noteId = this.localNoteDetails.note_ident;
            axios.post(`${apiDirectory}/get-note-assignations`, { note_ident: noteId })
                .then(response => {
                    this.assignationsData = response.data.map(assignation => ({
                        ...assignation,
                        unidad: assignation.unit.unit_short_id,
                        operador: assignation.operator.employee_ident,
                    }));
                    //Used afterwards to compare if the original or the updated value should be used when making an update
                    this.initialAssignations = JSON.parse(JSON.stringify(this.assignationsData));
                })
                .catch(error => {
                    console.error('Error fetching assignations:', error);
                });
        },
        toISOFormatWithTimezone(dateString) {
            if (!dateString) return null;
            try {
                const date = new Date(dateString);
                let isoDate = date.toISOString();
                return isoDate;
            }
            catch (error) {
                console.error(error);
                throw new Error('Failed to format date str: ' + error.message);
            }
        },
        async saveAssignation(assignationData, index) {
            let payload;
            // check if indeed there was a change. If the original time and current time are different, plus current is not null, set flag to true
            const start_time_updated = assignationData.start_time != null && (assignationData.start_time != this.initialAssignations[index].start_time);
            const arrival_time_updated = assignationData.arrival_time != null && (assignationData.arrival_time != this.initialAssignations[index].arrival_time);
            const contact_time_updated = assignationData.contact_time != null && (assignationData.contact_time != this.initialAssignations[index].contact_time);
            const end_time_updated = assignationData.end_time != null && (assignationData.end_time != this.initialAssignations[index].end_time);
            try {
                payload = {
                    // Depending on the flag control wether to use the (new) current value, or the existing one
                    start_time: start_time_updated ? this.toISOFormatWithTimezone(assignationData.start_time) : this.initialAssignations[index].start_time,
                    arrival_time: arrival_time_updated ? this.toISOFormatWithTimezone(assignationData.arrival_time) : this.initialAssignations[index].arrival_time,
                    contact_time: contact_time_updated ? this.toISOFormatWithTimezone(assignationData.contact_time) : this.initialAssignations[index].contact_time,
                    end_time: end_time_updated ? this.toISOFormatWithTimezone(assignationData.end_time) : this.initialAssignations[index].end_time,
                };
            }
            catch (error) {
                console.error(error)
                Swal.fire({
                    title: 'Error!',
                    text: 'Se recibio un valor inválido de tiempo para la actualización. Verifique los datos que introdujo.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                })
                return
            }
            const apiDirectory = process.env.API_DIRECTORY;
            const assignationId = assignationData.id;

            try {
                await axios.patch(`${apiDirectory}/assignation-detail/${assignationId}/`, payload);

                if (assignationData.start_time) {
                    this.localNoteDetails.status = 1;
                    await this.saveNote();
                }

                Swal.fire({
                    title: '¡Completado!',
                    text: 'La hora y fecha se actualizó con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(); // Reload the page after 'Ok' is clicked
                    }
                });
            } catch (error) {
                console.error('Error al actualizar la asignación:', error);
                Swal.fire('Error', 'Hubo un problema al actualizar las asignaciones', 'error');
            }
        },
        formatDateTime(isoString) {
            if (!isoString) {
                // If the string is empty or null, returns the message
                return "No hay datos de tiempo";
            }

            // Check if the timezone offset contains seconds and remove them if present
            const timezoneIndex = isoString.lastIndexOf('-') !== -1 ? isoString.lastIndexOf('-') : isoString.lastIndexOf('+');
            let timezone = isoString.substring(timezoneIndex);
            const timezoneParts = timezone.split(':');
            if (timezoneParts.length > 2) {
                // Remove seconds from the timezone offset
                timezone = `${timezoneParts[0]}:${timezoneParts[1]}`;
            }
            const isoStringWithoutSeconds = isoString.substring(0, timezoneIndex) + timezone;

            // Attempt to create a Date object with the corrected ISO string
            const date = new Date(isoStringWithoutSeconds);

            // Check if the date is 'Invalid Date'
            if (isNaN(date.getTime())) {
                return "No hay datos de tiempo";
            }

            const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };

            const formattedDate = date.toLocaleDateString('es-MX', optionsDate);
            const formattedTime = date.toLocaleTimeString('es-MX', optionsTime).toLowerCase();

            // Combine the parts by eliminating AM/PM repetition
            const matchAmPm = formattedTime.match(/am|pm/);
            const amPm = matchAmPm ? ` ${matchAmPm[0]}` : '';
            return `${formattedTime.replace(/am|pm/, '').trim()} del ${formattedDate}${amPm}`;
        },
        async fetchRecommendations() {
            const noteID = this.$route.params.noteID; // Get noteID from route params
            this.recommendations = [
                { 'unit': 'Cargando', 'time_queue': 'Cargando' },
                { 'unit': 'Cargando', 'time_queue': 'Cargando' },
                { 'unit': 'Cargando', 'time_queue': 'Cargando' },
                { 'unit': 'Cargando', 'time_queue': 'Cargando' },
                { 'unit': 'Cargando', 'time_queue': 'Cargando' }]
            this.recommenderLoading = true; 
            this.$forceUpdate();
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/recommend-tow`, { "note_ident": noteID });
                //this.recommendations = response.data;
                this.recommendations = [...response.data];
                while (this.recommendations.length < 5) {
                this.recommendations.push({ unit: "No disponible", time_queue: "No disponible" });
                }
                this.greenVehicleNames.value = this.recommendations.map(rec => rec.unit);
                //console.log(this.greenVehicleNames.value);
                this.redMarkerPosition.value = {lat: parseFloat(this.noteDetails.destination.lat), lng: parseFloat(this.noteDetails.destination.lon)};
                this.blockRecommend = false;
                this.$forceUpdate();
                //console.log("recommendations are", this.recommendations)
            }
            catch (error) {
                this.recommenderLoading = false; 
                console.error("Failed to fetch recommendations:", error);
                if (error.response.status == 500) {
                    Swal.fire(
                        '¡Error!',
                        'Ocurrió un error al obtener las recomendaciones\n Intente de nuevo. Si el problema persiste, contacte a su administrador',
                        'error'
                    );
                }
                this.recommendations = [
                    { 'unit': 'Error', 'time_queue': 'Error' },
                    { 'unit': 'Error', 'time_queue': 'Error' },
                    { 'unit': 'Error', 'time_queue': 'Error' },
                    { 'unit': 'Error', 'time_queue': 'Error' },
                    { 'unit': 'Error', 'time_queue': 'Error' }]
            }
        },
        sanitizeForm() {
            /**Used to sanitize values before submitting any changes */

            //Since not all notes require km, change empty str to null
            this.localNoteDetails.source_km == "" ? this.localNoteDetails.source_km = null : true
            this.localNoteDetails.destination_km == "" ? this.localNoteDetails.destination_km = null : true
            //Since user is advised to not fill model if unkown, check if empty then change to null if its the case
            this.localNoteDetails.vehicle_model == "" ? this.localNoteDetails.vehicle_model = null : true
        },
        validatePositiveIntegerField(event) {
            /**Used to validate positive integer fields */
            if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault();
            }
        },
        validateFloatField(event) {
            const charCode = event.charCode;
            const value = event.target.value;

            const isDigit = charCode >= 48 && charCode <= 57;
            const isMinus = charCode === 45 && value.indexOf('-') === -1;
            const isDot = charCode === 46 && value.indexOf('.') === -1;

            if (!isDigit && !isMinus && !isDot) {
                event.preventDefault();
            }
        },
        async FetchBrands() {
            /** Fetches the brands from the db an passes it to the brands prop */
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-brands`);
                // Tmp dict to hold the response after processing 
                const brandsDict = {};
                // Convert response into dict of form: {pk:brand}
                response.data.forEach(brand => {
                    //brandsDict[brand.pk] = brand.brand;  // Adjust based on actual keys in your data
                    brandsDict[brand.brand] = brand.pk;  // Adjust based on actual keys in your data
                });
                //console.log(brandsDict)
                this.brands = brandsDict
            }
            catch (error) {
                console.error("Failed to fetch brands", error);
                //console.log(error.response)
                if (error.response.status == 400) {
                    Swal.fire('Error', "Hubo un problema con la solicitud. Intentalo nuevamente.\n\
                    Si el problema persiste, contacte a su administrador.", 'error');
                    return
                }
                if (error.response.status == 500) {
                    Swal.fire('Error', "Ocurrió un error desconocido. Intentalo nuevamente.\n\
                    Si el problema persiste, contacte a su administrador.", 'error');
                }
            }
        },
        async FetchFilteredSubBrands(event){
            // Grab the value from the brand selector 
            this.subBrandLoading = true
            const selectedBrandPk = event.target.value;
            // Checks if there was a change in the brand. If so, reset the vue model value to null to prevent form submission until a conscius selection is made
            selectedBrandPk !== this.localNoteDetails.vehicle_brand ?  this.localNoteDetails.vehicle_sub_brand = null : true;
            try { 
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/get-filtered-sub-brands`,{"brand_id":selectedBrandPk});
                // Tmp dict to hold the response after processing 
                const subBrandsDict = {};
                // Convert response into dict of form: {id:sub_brand + "Clase: "+ required_class}
                const classes={1:'A',2:'B',3:'C',4:'D'}
                response.data.forEach(sub_brand => {
                    subBrandsDict[sub_brand.sub_brand+' '+'-- Clase: '+classes[sub_brand.required_unit_type]] = sub_brand.id;  // Adjust based on actual keys in your data
                });
                this.sub_brands = subBrandsDict;
                this.subBrandLoading = false
            }
            catch (error) {
                console.error("Failed to fetch brands", error);
                //console.log(error)
                if (error.response.status == 400) {
                    Swal.fire('Error', "Hubo un problema con la solicitud. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');

                }
                if (error.response.status == 500) {
                    Swal.fire('Error', "Ocurrió un error desconocido. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');
                }
            }
        },
        async SetStartingBrandSubBrand(){
            try { 
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/get-sub-brand`,{"sub_brand_id":this.noteDetails.vehicle_sub_brand});
                // Tmp dict to hold the response after processing 
                this.localNoteDetails.vehicle_brand = this.brands[response.data.brand];
                this.localNoteDetails.vehicle_sub_brand = response.data.id;
                //const classes={1:'A',2:'B',3:'C',4:'D'};
                const syntheticEvent = {
                    target: {
                        value: this.localNoteDetails.vehicle_brand 
                    }
                };
                await this.FetchFilteredSubBrands(syntheticEvent);
            }
            catch (error) {
                console.error("Failed to fetch brands", error);
                //console.log(error.response)
                if (error.response.status == 400) {
                    Swal.fire('Error', "Hubo un problema con la solicitud. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');

                }
                if (error.response.status == 500) {
                    Swal.fire('Error', "Ocurrió un error desconocido. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');
                }
            }
        },
        isRecommendationValid(recommendation) {
            return recommendation['unit'] != 'No disponible';
        },
        async fetchImages() {
            //Retrieves all the images linked to the current note 
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-files/`, {
                    params: {
                        object_id: this.localNoteDetails.note_ident,
                        object_type: 'note',
                    },
                });
                const files = response.data;
                console.log("Retrieved images: ",response.data)
                this.clearImages();
                this.populateImages(files);
                this.buttonClicked.images_tab = true;
                //console.log('Populated image blobs are ',this.imageSections)
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        },
        populateImages(files) {
            files.forEach(file => {
                const section = this.imageSections.find(section => section.loc === file.metadata.loc);
                if (section) {
                    section.images.uploaded.push(`data:image/jpeg;base64,${file.content}`); 
                    section.images.blob_ids.push(file.file_id);
                }
            });
        },
        clearImages() {
            this.imageSections.forEach(section => {
                section.images.uploaded = [];
                section.images.blob_ids = [];
            });
        },
        getProportionalCompressionQuality(size) {
            // Define the max size and min/max quality
            const maxSize = 10000000; // 10MB as the maximum file size for the highest quality
            const minQuality = 0.4; // Minimum quality
            const maxQuality = 0.9; // Maximum quality

            // Calculate the proportional quality
            const quality = Math.max(minQuality, Math.min(maxQuality, maxQuality - (size / maxSize) * (maxQuality - minQuality)));

            return quality;
        },
        // Function to compress and convert to JPEG
        async compressAndConvertToJPG(file) {
            return new Promise((resolve, reject) => {
                // Determine the compression quality based on the file size
                const initialSize = file.size;
                const quality = this.getProportionalCompressionQuality(initialSize);

                new Compressor(file, {
                    quality: quality,
                    convertSize: 5000000, // Convert if size is larger than 5MB
                    success(result) {
                        const reader = new FileReader();
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = () => reject(new Error('Error reading the file.'));
                        reader.readAsDataURL(result);
                    },
                    error(err) {
                        reject(err);
                    },
                });
            });
        },
        selectFile() {
            // Used to handle the image uploads 
            return new Promise((resolve) => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*'; 
                input.onchange = (event) => {
                    resolve(event.target.files[0]);
                };
                input.click();
            });
        },
        async uploadFile(section) {
            // Handles the upload of images to the upload-file endpoint
            const file = await this.selectFile();
            if (!file) {
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ocurrio un error al subir el archivo. Intente de nuevo.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // return from func
                        return;
                    }
                });
                return;
            }

            try {
                const compressedFile = await this.compressAndConvertToJPG(file);

                // Create a new blob from the base64 data
                const response = await fetch(compressedFile);
                const blob = await response.blob();
                const jpgFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });

                const formData = new FormData();
                formData.append('file', jpgFile);
                formData.append('object_type', 'note');
                formData.append('object_id', this.localNoteDetails.note_ident);
                formData.append('object_metadata', JSON.stringify({ 'loc': section.loc }));

                const apiDirectory = process.env.API_DIRECTORY;
                await axios.post(`${apiDirectory}/upload-file`, formData);
                this.fetchImages();
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },
        downloadAllImages(section) {
            // Get the list of images for the specified section
            const images = section.images.uploaded;

            if (images.length === 0) {
                Swal.fire({
                    title: '¡Error!',
                    text: 'No hay imágenes en esta sección.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // return from func
                        return
                    }
                });
            }

            // Trigger download for each image
            images.forEach((image, index) => {
                const link = document.createElement('a');
                link.href = image; // Image data URL
                link.download = `${section.loc}_${index + 1}.jpg`; // Name the downloaded file
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
            Swal.fire({
                    title: '¡Completado!',
                    text: 'Descarga realizada',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                })
        },
        async deleteImage(section, index) {

            // Find the corresponding blob ID
            const blobId = section.images.blob_ids[index];

            if (!blobId) {
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ocurrio un error al eliminar el archivo. Intente de nuevo. Si el problema persiste, contacte a su administrador.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        return
                    }
                });
            }

            try {
                const apiDirectory = process.env.API_DIRECTORY;
                await axios.post(`${apiDirectory}/delete-file/`, { blob_id: blobId });

                // Remove the image from the section and the blob id from the dict 
                section.images.uploaded.splice(index, 1);
                section.images.blob_ids.splice(index, 1);


                Swal.fire({
                    title: '¡Completado!',
                    text: 'La imagen se eliminó con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                })
            } catch (error) {
                console.error('Error deleting image:', error);
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ocurrio un error al procesar la solicitud para eliminar la imagen. Por favor intente de nuevo. Si el problema periste, contacte a su administraodr.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // return from func
                        return
                    }
                });
            }
        },
    },
    async created() {
        // Here you can add any additional initialization that needs to happen when the component is created
        // For instance, setting initial form values based on props
        this.localNoteDetails = { ...this.noteDetails };
        this.greenVehicleNames.value = [];
        this.redMarkerPosition.value = { lat: 25.689214, lng: -100.314343 }; 
        this.fetchClients();
        this.fetchClientById(this.localNoteDetails.client);
        await this.FetchBrands()
        await this.SetStartingBrandSubBrand()        
        this.blockEdit = this.noteDetails.status == 2 || this.noteDetails.status == 4 // Determines wheter editing the note should be enabled or disabled based on status
        this.blockRecommend = true; // Safelock to prevent buttons from being used before recommendations are computed
        this.recommenderLoading = false; // Safelock to prevent recommender from being invoked multiple times 
        this.recommendations = [
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' }]
    }
};
</script>